import { z } from 'zod'

export const UsernameSchema = z
	.string({ required_error: 'Usuario es requerido' })
	.min(3, { message: 'Usuario es muy corto' })
	.max(20, { message: 'Usuario es muy largo' })
	.regex(/^[a-zA-Z0-9_]+$/, {
		message: 'El usuario solo puede incluir letras, numeros, y guión bajo',
	})
	// users can type the username in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const PasswordSchema = z
	.string({ required_error: 'Campo requerido' })
	.min(6, { message: 'La contraseña es muy corta' })
	.max(100, { message: 'La contraseña es muy larga' })

export const NameSchema = z
	.string({ required_error: 'El nombre es requerido' })
	.min(3, { message: 'El nombre es muy corto' })
	.max(40, { message: 'El nombre es muy largo' })

export const LastnameSchema = z
	.string({ required_error: 'El apellido es requerido' })
	.min(3, { message: 'El apellido es muy corto' })
	.max(40, { message: 'El apellido es muy largo' })

export const EmailSchema = z
	.string({ required_error: 'Email es requerido' })
	.email({ message: 'Email es inválido' })
	.min(3, { message: 'Email es muy corto' })
	.max(100, { message: 'Email es muy largo' })
	// users can type the email in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const PasswordAndConfirmPasswordSchema = z
	.object({ password: PasswordSchema, confirmPassword: PasswordSchema })
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'Las contraseñas deben coincidir',
			})
		}
	})

export const DefaultNumberSchema = z
	.number({required_error: 'Campo requerido'})
	
export const DefaultStringSchema = z
	.string({required_error: 'Campo requerido'})